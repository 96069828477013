const wordList = [
"advice",
"africa",
"agent",
"air",
"airplane",
"airport",
"alien",
"alps",
"amazon",
"ambulance",
"america",
"angel",
"antarctica",
"anthem",
"apparel",
"apple",
"arm",
"astronaut",
"atlantis",
"australia",
"autograph",
"aztec",
"back",
"bagel",
"ball",
"balloon",
"banana",
"band",
"banister",
"bank",
"bar",
"bark",
"barrel",
"bass",
"bat",
"battery",
"beach",
"bean",
"bear",
"beat",
"bed",
"beef",
"beetle",
"beginning",
"beijing",
"bell",
"belt",
"bench",
"berlin",
"bermuda",
"berry",
"bike",
"bill",
"blanket",
"block",
"board",
"bolt",
"bomb",
"bond",
"boom",
"boot",
"bottle",
"bow",
"box",
"bracelet",
"brake",
"bread",
"bridge",
"brush",
"bubble",
"buck",
"bucket",
"buffalo",
"bug",
"bugle",
"bunkbed",
"burger",
"bush",
"button",
"cable",
"cake",
"calf",
"camera",
"campsite",
"can",
"canada",
"candy",
"cap",
"capital",
"car",
"card",
"carrot",
"case",
"casino",
"cast",
"cat",
"catnip",
"cell",
"centaur",
"center",
"chair",
"chalk",
"change",
"charge",
"charger",
"check",
"chef",
"chess",
"chest",
"chick",
"chicken",
"china",
"chocolate",
"church",
"circle",
"clerk",
"cliff",
"cloak",
"cloud",
"club",
"code",
"coffee",
"coin",
"cold",
"college",
"comb",
"comic",
"compound",
"computer",
"concert",
"conductor",
"console",
"contract",
"cook",
"cookie",
"copper",
"corner",
"cotton",
"couch",
"country",
"coupon",
"court",
"cover",
"covfefe",
"cow",
"cracker",
"crane",
"crash",
"crayon",
"cream",
"cricket",
"cross",
"crosshair",
"crow",
"crown",
"cup",
"cupholder",
"curious",
"cycle",
"czech",
"daisy",
"dance",
"dashboard",
"date",
"day",
"death",
"deck",
"defender",
"degree",
"dentist",
"desk",
"diamond",
"dice",
"dinosaur",
"disease",
"disgust",
"dish",
"dishwasher",
"doctor",
"dog",
"donkey",
"donut",
"downtown",
"draft",
"dragon",
"drain",
"drawer",
"dream",
"dress",
"drill",
"drink",
"drip",
"drop",
"drum",
"duck",
"duct",
"dwarf",
"eagle",
"earth",
"egypt",
"embassy",
"engine",
"england",
"envelope",
"eraser",
"europe",
"eye",
"face",
"facsimile",
"fair",
"fall",
"falsehood",
"family",
"fan",
"fast",
"fat",
"fatal",
"faucet",
"fence",
"fiddle",
"field",
"fighter",
"figure",
"file",
"film",
"fire",
"firefly",
"fireman",
"fireplace",
"fish",
"fisherman",
"flag",
"flashlight",
"flex",
"flint",
"flowerpot",
"flute",
"fly",
"foliage",
"foot",
"force",
"forecast",
"forehead",
"forest",
"forgery",
"forgiveness",
"fork",
"fortress",
"foundation",
"foyer",
"frame",
"france",
"freshman",
"friend",
"fries",
"frostbite",
"frosting",
"fugitive",
"furniture",
"gadget",
"game",
"garbage",
"garden",
"garlic",
"garment",
"gas",
"gavel",
"gazebo",
"genie",
"genius",
"germany",
"ghost",
"giant",
"gift",
"gladiator",
"glass",
"glasses",
"globe",
"glove",
"gold",
"grace",
"graduate",
"grandchild",
"grape",
"grass",
"greece",
"green",
"grenade",
"grill",
"ground",
"guitar",
"gun",
"ham",
"hammer",
"hand",
"hawk",
"head",
"headache",
"headlamp",
"headphones",
"heart",
"helicopter",
"highway",
"himalayas",
"hobby",
"hole",
"hollywood",
"honey",
"hood",
"hoof",
"hook",
"horn",
"horse",
"horseshoe",
"hospital",
"hotel",
"hymn",
"ice",
"ice cream",
"india",
"iron",
"ivory",
"jack",
"jacket",
"jackpot",
"jalapeno",
"jam",
"jar",
"jelly",
"jellyfish",
"jester",
"jet",
"jewel",
"jinx",
"jogger",
"journal",
"journalist",
"joystick",
"juice",
"jumpsuit",
"junction",
"jupiter",
"juror",
"justice",
"juvenile",
"kangaroo",
"ketchup",
"kettle",
"key",
"keyboard",
"kid",
"king",
"kitten",
"kiwi",
"knife",
"knight",
"lab",
"lace",
"lake",
"lamb",
"lamp",
"lap",
"lasagna",
"laser",
"lawyer",
"lead",
"leaf",
"lemon",
"leprechaun",
"lettuce",
"life",
"lifeboat",
"light",
"limb",
"limousine",
"line",
"link",
"lion",
"litter",
"liver",
"loch ness",
"lock",
"log",
"lollipop",
"london",
"luck",
"lung",
"luxury",
"magic",
"magnet",
"mail",
"mammoth",
"manager",
"maple",
"marble",
"march",
"marker",
"market",
"mass",
"match",
"mercury",
"mexico",
"microscope",
"milk",
"millionaire",
"mine",
"mint",
"mirror",
"missile",
"mission",
"model",
"mold",
"mole",
"money",
"monitor",
"monkey",
"monster",
"moon",
"moscow",
"motel",
"mount",
"mountain",
"mouse",
"mouth",
"mug",
"music",
"nail",
"nation",
"necklace",
"needle",
"nephew",
"net",
"newspaper",
"niece",
"new york",
"night",
"nightmare",
"ninja",
"note",
"novel",
"nucleus",
"number",
"nurse",
"nut",
"octopus",
"office",
"officer",
"oil",
"olive",
"olympus",
"onion",
"opera",
"orange",
"organ",
"outlet",
"oven",
"paddle",
"painful",
"palm",
"pan",
"pancake",
"pants",
"paper",
"parachute",
"park",
"part",
"pass",
"passport",
"pasta",
"paste",
"pavement",
"peace",
"pear",
"peas",
"pedestal",
"pen",
"pencil",
"pendant",
"penguin",
"penny",
"phoenix",
"phone",
"photograph",
"piano",
"picture",
"pie",
"pillow",
"pilot",
"pin",
"pipe",
"pirate",
"pistol",
"pit",
"pitch",
"pizza",
"plane",
"plankton",
"plant",
"plastic",
"plate",
"platypus",
"play",
"plot",
"pocket",
"point",
"poison",
"pole",
"police",
"pony",
"pool",
"pop",
"port",
"portal",
"portrait",
"post",
"pound",
"powder",
"power",
"present",
"president",
"press",
"princess",
"pump",
"pumpkin",
"punch",
"pupil",
"puppet",
"puppy",
"purple",
"pyramid",
"queen",
"quilt",
"rabbit",
"rack",
"racket",
"rainbow",
"rainforest",
"rancher",
"range",
"rat",
"ray",
"recycling",
"referee",
"reflection",
"renegade",
"restaurant",
"revolution",
"ring",
"river",
"robin",
"robot",
"rock",
"rocket",
"rollercoaster",
"rome",
"root",
"rose",
"rotor",
"roulette",
"round",
"row",
"ruler",
"runway",
"sail",
"sandwich",
"satellite",
"saturn",
"sauce",
"scale",
"school",
"scientist",
"scissors",
"scorpion",
"screen",
"scuba diver",
"sea",
"seal",
"seatbelt",
"server",
"shadow",
"shakespeare",
"shark",
"sheep",
"ship",
"shipwreck",
"shirt",
"shoe",
"shoes",
"shop",
"shorts",
"shot",
"shovel",
"shower",
"sideline",
"sink",
"sky",
"skyscraper",
"slip",
"slug",
"smell",
"smuggler",
"sneeze",
"snow",
"snowman",
"soap",
"sock",
"socket",
"soldier",
"sole",
"soul",
"sound",
"soup",
"space",
"spaceship",
"spaghetti",
"speaker",
"spell",
"spice",
"spider",
"spike",
"spine",
"spot",
"spring",
"sprinkler",
"spy",
"square",
"stadium",
"staff",
"star",
"state",
"steam",
"stick",
"stock",
"stone",
"stool",
"straw",
"stream",
"street",
"strike",
"string",
"student",
"study",
"sub",
"sugar",
"suit",
"sun",
"superhero",
"sushi",
"swing",
"switch",
"syrup",
"table",
"tablet",
"taco",
"tag",
"tail",
"tank",
"tap",
"taxi",
"tea",
"teacher",
"telescope",
"television",
"temple",
"tent",
"test",
"theater",
"thermostat",
"thief",
"thrill",
"throne",
"thumb",
"tick",
"tie",
"tiger",
"time",
"tire",
"tissue",
"toast",
"toaster",
"tokyo",
"tomato",
"tooth",
"torch",
"touchdown",
"towel",
"tower",
"track",
"train",
"trash",
"tree",
"triangle",
"trim",
"trip",
"trunk",
"tube",
"tunnel",
"turkey",
"turtle",
"twig",
"undertaker",
"unicorn",
"usher",
"vacation",
"vacuum",
"van",
"vent",
"verse",
"vet",
"waffle",
"waiter",
"wake",
"wall",
"war",
"washer",
"washington",
"watch",
"water",
"wave",
"wax",
"weather",
"web",
"well",
"whale",
"wheel",
"whip",
"wilderness",
"wind",
"witch",
"worm",
"wrench",
"yacht",
"yankee",
"yard",
"yarn",
"yawn",
"yogurt",
"yoyo",
];

export const Words = {
	random: (test) => {
        const i = Math.floor(Math.random() * wordList.length);
        return wordList[i];
	},
    pick: (test) => {
        // xxx need to protect against infinite loops
        while (true) {
            const word = Words.random();
            if (test(word))
                return word;
        }
    },
	toRoot: (word) => {
		return word.toLowerCase();
	},
};
