import { h, render, Component } from "preact";
import { useState } from 'preact/hooks';
import { Client, LobbyClient } from 'boardgame.io/client';
import { SocketIO } from 'boardgame.io/multiplayer'
import { DontTakeMyClue } from './game';
import { Route } from "wouter-preact";

const SERVER = "";

// helpers -- not sure why something like this
// doesn't exist inside the client already...

function isActive(ctx, playerID) {
    return ctx.activePlayers === null ? isTurn(ctx, playerID) : playerID in ctx.activePlayers;
}

function isTurn(ctx, playerID) {
    return ctx.currentPlayer == playerID;
}

function activeStage(ctx, playerID) {
    return playerID in ctx.activePlayers ? ctx.activePlayers[playerID] : null;
}

class App extends Component {
    constructor() {
        super();
        this.lobby = new LobbyClient({server: SERVER});
        this.game = DontTakeMyClue.name;
    }
    render() {
        return h("div", null,
            h(Route, {path: "/"}, () => h("div", null,
		    h("div", {className: "logo"}, h("h1", null, "Rules:"), h("h2", null, "1. Don't Take My Clue")),
                    h(MatchList, {lobby: this.lobby, game: this.game}))),
            h(Route, {path: "/:matchID"}, props => h(MatchLoader, {lobby: this.lobby, game: this.game, ...props})),
            h(Route, {path: "/:matchID/:playerID/:credentials"}, props => h(MatchClient, {...props})),
        );
    }
}

class MatchList extends Component {
    constructor({lobby, game}) {
        super();
        this.lobby = lobby;
        this.game = game;
        this.setState({
            loaded: false,
            matches: [],
        });
    }
    componentDidMount() {
        this.loadMatches();
    }
    loadMatches() {
        this.setState({loaded: false, matches: []});
        this.lobby.listMatches(this.game)
            .then(({matches}) => this.setState({loaded: true, matches}));
    }
    newMatch(players) {
        this.lobby.createMatch(this.game, {numPlayers: players, unlisted: false, setupData: {}})
            .then(({matchID}) => window.location = '/' + matchID);
    }
    renderMatch(match) {
        const joined = match.players.filter(p => !!p.name);
        return h("li", null, h("a", {href: "/" + match.matchID}, match.gameName, " - ", joined.length, " of ", match.players.length));
    }
    render() {
        if (!this.state.loaded) {
            return h("span", null, "Loading...");
        }
        return h('div', null,
            h(NewMatchForm, {onNewMatch: players => this.newMatch(players)}),
        );
    }
}

class NewMatchForm extends Component {
    constructor() {
        super();
        this.setState({
            players: 2,
        });
    }
    render() {
        return h("div", {className: "new-game-form"},
            h("select", {onChange: e => this.setState({players: parseInt(e.target.value, 10)})}, [2, 3, 4, 5, 6, 7, 8].map(i => h("option", {value: i}, i, " players"))),
            h("button", {type: "button", onClick: () => this.props.onNewMatch(this.state.players)}, "New Game"));
    }
}

class MatchLoader extends Component {
    constructor({lobby, game, matchID, playerID, credentials}) {
        super();
        this.lobby = lobby;
        this.game = game;
        this.matchID = matchID;

        if (!credentials && localStorage.getItem(matchID)) {
            const cred = JSON.parse(localStorage.getItem(matchID));
            playerID = cred.playerID;
            credentials = cred.credentials;
        }

        this.state = {
            loaded: false,
            match: null,
            name: "",
            playerID: playerID,
            credentials: credentials,
        };
    }
    componentDidMount() {
        this.loadMatch();
    }
    loadMatch() {
        return this.lobby.getMatch(this.game, this.matchID)
            .then(match => this.setState({loaded: true, match: match}))
            // XXX handle errors
            .catch(() => this.setState({loaded: true}));
    }
    joinMatch() {
        this.lobby.joinMatch(this.game, this.matchID, {playerName: this.state.name})
            .then(({playerID, playerCredentials}) => {
                const cred = {playerID, credentials: playerCredentials};
                localStorage.setItem(this.matchID, JSON.stringify(cred));
                this.setState(cred);
            })
            // XXX handle errors
            .catch(() => {});
    }
    leaveMatch() {
        this.lobby.leaveMatch(this.game, this.matchID, {
                playerID: this.state.playerID,
                credentials: this.state.credentials
            })
            .then(() => {
                localStorage.removeItem(this.matchID);
                this.setState({playerID: null, credentials: null});
                return this.loadMatch();
            });
    }
    render() {
        const {loaded, match, playerID, credentials} = this.state;
        if (!loaded) {
            return h("div", null, "Loading...");
        }
        if (!match) {
            return h("div", null, "An error occurred.");
        }

        const open = match.players.filter(p => !p.name).length;
        if (open > 0 && !credentials) {
            return h("div", null,
                h("h1", null, open, " left"),
                h("form", {onSubmit: e => { this.joinMatch(); e.preventDefault(); }},
                    h("input", {type: "text", maxLength: 25, value: this.state.name, placeholder: "Name", onChange: e => this.setState({name: e.target.value})}),
                    h("button", {type: "button", onClick: () => this.joinMatch()}, "Join"),
                ));
        }

        // credentials may be blank, but then they're a spectator
        return h("div", {},
            h(MatchClient, {matchID: this.matchID, playerID, credentials}),
	    credentials ? h("a", {className:"game-link", href: new URL("/" + this.matchID + "/" + playerID + "/" + credentials, window.location)}, "🔗") : null,
	)
    }
}

class MatchClient extends Component {
    constructor(props) {
        super();
		this.client = Client({
            game: DontTakeMyClue,
            matchID: props.matchID,
            playerID: props.playerID,
            credentials: props.credentials,
            multiplayer: SocketIO({server: SERVER}),
            debug: false,
        });
    }
    componentDidMount() {
        this.unsubscribe = this.client.subscribe(() => this.forceUpdate());
        this.client.start();
    }
    componentWillUnmount() {
        this.client.stop();
        this.unsubscribe();
    }
    playerName(playerID) {
        return this.client.matchData.find(p => p.id == playerID)?.name;
    }
    render() {
        const state = this.client.getState();
        if (!state) {
            return h("div", null, "Loading...");
        }

        const {ctx, G} = state;

        if (ctx.gameover) {
            return h(Game, {players: this.client.matchData, log: this.client.log, G},
                h("h1", {}, "Game over!"));
        }

        // maybe make a different component for this?
        // would have to use two different routes...
        const open = this.client.matchData.filter(p => !p.name).length;
        if (open > 0) {
            const join = new URL("/" + this.client.matchID, window.location);
            return h(Game, {players: this.client.matchData, log: this.client.log, currentPlayer: ctx.currentPlayer, G},
                h("h1", null, "Waiting for " + open + " more..."),
                h("h2", {}, "Invite someone:"),
                h("div", {},
                    h("input", {className: "join-link", type: "text", value: join.toString(), onFocus: e => e.target.select()})),
            );
        }

        // are we an active player?
        const stage = activeStage(ctx, this.client.playerID);
        if (stage === 'clue') {
            // provide a clue
            const myclue = G.secret[this.client.playerID]||"";
            return h(Game, {players: this.client.matchData, log: this.client.log, currentPlayer: ctx.currentPlayer, G},
                h("h1", {}, "Give a clue..."),
                h("div", {className: "word"}, G.word),
                h(WordInput, {key: "clue", className: "clue-form" + (myclue ? " ready" : " not-ready"), value: myclue, onWord: clue => this.client.moves.giveClue(clue), label: "Ready"}),
            );
        } else if (stage === 'review') {
            return h(Game, {players: this.client.matchData, log: this.client.log, currentPlayer: ctx.currentPlayer, G},
                h("h1", {}, "Select clues to discard..."),
                h("div", {className: "word"}, G.word),
                h(ClueReview, {client: this.client, clues: G.secret}),
            );
        } else if (stage === 'guess') {
            // need to guess
            return h(Game, {players: this.client.matchData, log: this.client.log, currentPlayer: ctx.currentPlayer, G},
                h("h1", {}, "Make your guess!"),
                h(ClueList, {clues: G.clues, players: this.client.matchData}),
                h(WordInput, {key: "guess", className: "guess-form", value: "", label: "Guess", onWord: guess => this.client.moves.guessWord(guess)},
                    h("button", {type: "button", onClick: () => this.client.moves.skipWord()}, "Skip")),
            );
        }

       // COULD BE A SPECTATOR ANYWHERE BELOW....

        if (G.clues) {
            return h(Game, {players: this.client.matchData, log: this.client.log, currentPlayer: ctx.currentPlayer, G},
                h("h1", null, "Waiting for guess..."),
                h(ClueList, {clues: G.clues, players: this.client.matchData}),
            );
        }

        // waiting for clues...
        return h(Game, {players: this.client.matchData, log: this.client.log, currentPlayer: ctx.currentPlayer, G},
            h("h1", {}, "Waiting for clues..."),
            (stage === 'wait' ? h("button", {type: "button", onClick: () => this.client.moves.advance()}, "I hate waiting") : ""),
        );
    }
}

function Game(props) {
    return h("div", null,
        h(ScoreBoard, props.G),
        h(PlayerList, {players: props.players, clues: props.G.secret, current: props.currentPlayer}),
        props.children,
        h(WordLog, {players: props.players, log: props.log}),
    );
}

function PlayerList({players, current, clues}) {
    return h("div", {className: "player-list"},
        players.map(p => h("span", {className: "player" + (p.id == current ? ' guessing' : clues && !!clues[p.id] ? ' ready' : ' waiting')}, p.name)),
    );
}

function ScoreBoard({score, cards}) {
    return h("div", null, "Score: ", score, " Remaining: ", cards);
}

function ClueList({clues}) {
    const c = Object.entries(clues);
    return h("div", {className: "clue-list"},
        c.length ? c.map(([, clue]) => h("div", {className: "clue"}, clue)) : "Oof",
    );
}

function ClueReview({client, clues}) {
    const c = Object.entries(clues);
    const [selected, setSelected] = useState([]);
    function toggle(clue) {
        const s = [...selected], index = s.indexOf(clue);
        index === -1 ? s.push(clue) : s.splice(index, 1);
        setSelected(s);
    }
    return h("div", {},
        h("div", {className: "clue-list"},
            c.map(([, clue], index) => h("span", {key: index, className: "clue click" + (selected.indexOf(clue) !== -1 ? " selected" : ""), onClick: () => toggle(clue)}, clue))),
        h("button", {onClick: () => client.moves.done(selected)}, "Done"),
    );
}

function WordLog({players, log}) {
    // only looking for log events that were guesses or skips
    const hist = log.filter(l => l.action.type == "MAKE_MOVE" && (l.action.payload.type == "guessWord" || l.action.payload.type == "skipWord"))
        .map(l => ({...l.metadata, guess: l.action.payload.args[0], playerID: l.action.payload.playerID, turn: l.turn}))
        .reverse();
    const getName = (id) => players.find(p => p.id == id).name;
    return h("div", {},
        hist.map(log => {
            const clues = Object.entries(log.secret).map(([id, clue]) => {
                return h("div", {className: "clue"},
                    h("span", {className: (!(id in log.clues) ? " discarded" : "")}, clue),
                    h("span", {className: "clue-name"}, getName(id))
                );
            });
            return h("div", {key: log.turn, className: "hist" + (!log.guess ? ' skipped' : log.correct ? ' correct': ' wrong')},
                h("div", {className: "hist-word"}, log.word),
                h("div", {className: "hist-clues"}, clues),
                h("div", {className: "hist-guess"}, getName(log.playerID), (!log.guess ? " skipped" : ' guessed "' + log.guess + '"')),
            );
        }),
    );
}

class WordInput extends Component {
    constructor({value, onWord, label, className, key}) {
        super({onWord, label, className});
        this.state = {value: value||""};
    }
    render() {
        return h("form", {key: this.props.key, className: this.props.className, onSubmit: (e) => { e.preventDefault(); if (this.state.value) this.props.onWord(this.state.value); }},
            h("input", {type: "text", spellcheck: false, value: this.state.value, onInput: (e) => this.setState({value: e.target.value.replace(" ", "")}) }),
            h("button", {type: "button", onClick: () => this.props.onWord(this.state.value)}, this.props.label||"SUBMIT"),
            this.props.children,
        );
    }
}

render(h(App), document.body);
