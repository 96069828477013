import { Words } from './words';

export const DontTakeMyClue = {
	name: 'dtmc',

	// clues remain in "secret" until every player has given a clue; they
	// are then filtered to remove duplicates and put into "clues"; this
	// simplifies playerView and the client logic: if "clues" is present and
	// non-null, then we are in the guessing stage, otherwise we are waiting
	setup: () => ({score: 0, cards: 13, word: null, clues: null, secret: {}, words: []}),

	// each turn progresses through three stages:
	// - clue: everyone but current player gives a clue
	// - review: any clue giver can discard invalid clues
	// - guess: current player is presented clues and makes a guess
	turn: {
		onBegin: (G, ctx) => {
			// put everyone else into clue stage,
			// current player can be impatient
			ctx.events.setActivePlayers({
				currentPlayer: 'wait',
				others: 'clue',
				minMoves: 1,
			});
			const word = Words.random();
			return {...G, word, clues: null, secret: {}};
		},
		stages: {
			clue: {
				moves: {
					giveClue: {
						move: (G, ctx, clue) => {
							const secret = {...G.secret, [ctx.playerID]: clue};

							// because we want players to be able to change
							// their clues at any point during this stage,
							// we can't have a maxMove limit that ends the
							// stage automatically, so must end it ourselves
							const active = Object.entries(ctx.activePlayers).filter(p => p[1] == 'clue');

							if (Object.keys(secret).length == Object.keys(active).length) {
								// once everyone has given a clue, we move
								// all clue givers to the review phase
								ctx.events.setActivePlayers({others: 'review'});
							}
							return {...G, secret};
						},
						// needs to happen on the server so we
						// have the full context (all given clues)
						client: false,
						// don't want clues showing up in the logs
						redact: true,
					},
				}
			},
			wait: {
				moves: {
					advance: {
						move: (G, ctx) => {
							// if we got clues, need to advance to the review phase...
							// if there were no clues at all, go to guess
							if (Object.keys(G.secret).length > 0) {
								ctx.events.setActivePlayers({others: 'review'});
							} else {
								ctx.events.setActivePlayers({currentPlayer: 'guess'});
							}
							return G;
						},
						// needs the clues
						client: false,
					},
				},
			},
			review: {
				moves: {
					done: {
						move: (G, ctx, discard) => {
							// secret is {player: clue}; remove any clues that were discarded
							const clues = Object.entries(G.secret)
								.filter(c => discard.indexOf(c[1]) === -1);
							ctx.events.setActivePlayers({currentPlayer: 'guess'});
							return {...G, clues: Object.fromEntries(clues)};
						},
						client: false,
						redact: true,
					},
				},
			},
			guess: {
				moves: {
					guessWord: {
						move: (G, ctx, guess) => {
							const correct = guess.toLowerCase() == G.word.toLowerCase();

							// need to create copies of stuff in G, as it's a proxy object
							ctx.log.setMetadata({word: G.word, correct: correct, clues: {...G.clues}, secret: {...G.secret}});
							ctx.events.endTurn();

							if (correct) {
								// correct guess is a point and one card
								return {...G, score: G.score + 1, cards: G.cards - 1};
							}
							// an incorrect guess costs 2 cards; don't let
							// cards go negative if this is the last turn
							if (G.cards < 2) {
								// if the last card was an incorrect guess,
								// need to take a previously guessed card
								return {...G, cards: 0, score: Math.max(0, G.score - 1), cards: 0};
							}
							return {... G, cards: G.cards - 2};
						},
						client: false,
					},
					skipWord: {
						move: (G, ctx) => {
							ctx.log.setMetadata({word: G.word, correct: null, clues: {...G.clues}, secret: {...G.secret}});
							ctx.events.endTurn();

							// skip only costs one card
							return {...G, cards: G.cards - 1};
						},
						client: false,
					},
				},
			},
		}
	},
	playerView: (G, ctx, playerID) => {
		const active = (playerID in ctx.activePlayers) ? ctx.activePlayers[playerID] : null;
		// reviewers get to see everything
		if (active === 'review') {
			return G;
		}
		// each player can only see their own pending clue
		const secret = Object.fromEntries(Object.entries(G.secret).map(e => [e[0], (e[0] === playerID ? e[1] : true)]));
		return active === 'clue' ? {...G, secret} : {...G, secret, word: null};
	},
	endIf: G => {
		if (G.cards <= 0) {
			return {score: G.score};
		}
	},
};
